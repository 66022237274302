import React, {
    useContext, useCallback, useState, useEffect, useReducer, useRef
} from 'react';
import _ from 'lodash';
import { TranslatorContext } from '@jutro/locale';
import { WizardPage, wizardProps } from 'gw-portals-wizard-react';
import { useValidation } from 'gw-portals-validation-react';
import { useAuthentication } from 'gw-digital-auth-react';
import { useDependencies } from 'gw-portals-dependency-react';
import {
    useErrorHandler,
    QB_STEPS,
    AppContext
} from 'nfum-portals-utils-react';
import { ViewModelServiceContext, ViewModelForm } from 'gw-portals-viewmodel-react';
import styles from './PropertyDetailsPage.module.scss';
import metadata from './PropertyDetailsPage.metadata.json5';
import messages from './PropertyDetailsPage.messages';
import commonMessages from '../../NGHWizard.messages';
import ReferralMessage from '../../components/ReferralMessageComponent/ReferralMessageComponent';
import useTagManager from '../../hooks/useTagManager';
import useCleanPayload from '../../hooks/useCleanPayload';

const actions = Object.freeze({
    SHOW_TYPE_OF_PROPERTY_REFERRAL: 'showTypeOfPropertyReferral',
    SHOW_YEAR_BUILT_REFERRAL: 'showYearBuiltReferral',
    SHOW_PROPERTY_LISTED_REFERRAL: 'showPropertyListedReferral',
    SHOW_HOME_OCCUPANCY_REFERRAL: 'showHomeOccupancyReferral',
    SHOW_RURAL_PURSUIT_REFERRAL: 'showRuralPursuitReferral',
    SHOW_WALL_MATERIAL_REFERRAL: 'showWallMaterialReferral',
    SHOW_CONSTRUCTION_REFERRAL_WALL: 'showConstructionReferral',
    SHOW_CONSTRUCTION_REFERRAL_ROOF: 'showConstructionReferralRoof',
    SHOW_ROOF_MATERIAL_REFERRAL: 'showRoofMaterialReferral',
    SHOW_BEDROOMS_REFERRAL: 'showBedroomsReferral',
    SHOW_INITIAL_STATE: 'showInitialState',
});

const initialState = {
    showAboutTheBuildingContainer: true,
    showConstructionMaterialsContainer: true,
    showOutsideYourHomeContainer: true,
    showHomeType: true,
    showTypeOfProperty: true,
    showBedrooms: true,
    showYearBuilt: true,
    showBasementOrCellar: true,
    showPropertyListed: true,
    showHomeOccupancy: true,
    showRuralPursuit: true,
    showWallRoofType: true,
    showWallMaterial: true,
    showRoofMaterial: true
};

const reducer = (state, action) => {
    switch (action.type) {
        case actions.SHOW_TYPE_OF_PROPERTY_REFERRAL:
            return {
                // eslint-disable-next-line max-len
                ...state, showAboutTheBuildingContainer: true, showConstructionMaterialsContainer: false, showOutsideYourHomeContainer: false, showHomeType: true, showTypeOfProperty: true, showBedrooms: false, showYearBuilt: false, showBasementOrCellar: false, showPropertyListed: false, showHomeOccupancy: false, showRuralPursuit: false, showWallRoofType: false, showWallMaterial: false, showRoofMaterial: false
            };
        case actions.SHOW_BEDROOMS_REFERRAL:
            return {
                // eslint-disable-next-line max-len
                ...state, showAboutTheBuildingContainer: true, showConstructionMaterialsContainer: false, showOutsideYourHomeContainer: false, showHomeType: false, showTypeOfProperty: false, showBedrooms: true, showYearBuilt: false, showBasementOrCellar: false, showPropertyListed: false, showHomeOccupancy: false, showRuralPursuit: false, showWallRoofType: false, showWallMaterial: false, showRoofMaterial: false
            };
        case actions.SHOW_YEAR_BUILT_REFERRAL:
            return {
                // eslint-disable-next-line max-len
                ...state, showAboutTheBuildingContainer: true, showConstructionMaterialsContainer: false, showOutsideYourHomeContainer: false, showHomeType: false, showTypeOfProperty: false, showBedrooms: false, showYearBuilt: true, showBasementOrCellar: false, showPropertyListed: false, showHomeOccupancy: false, showRuralPursuit: false, showWallRoofType: false, showWallMaterial: false, showRoofMaterial: false
            };
        case actions.SHOW_PROPERTY_LISTED_REFERRAL:
            return {
                // eslint-disable-next-line max-len
                ...state, showAboutTheBuildingContainer: true, showConstructionMaterialsContainer: false, showOutsideYourHomeContainer: false, showHomeType: false, showTypeOfProperty: false, showBedrooms: false, showYearBuilt: false, showBasementOrCellar: false, showPropertyListed: true, showHomeOccupancy: false, showRuralPursuit: false, showWallRoofType: false, showWallMaterial: false, showRoofMaterial: false
            };
        case actions.SHOW_HOME_OCCUPANCY_REFERRAL:
            return {
                // eslint-disable-next-line max-len
                ...state, showAboutTheBuildingContainer: true, showConstructionMaterialsContainer: false, showOutsideYourHomeContainer: false, showHomeType: false, showTypeOfProperty: false, showBedrooms: false, showYearBuilt: false, showBasementOrCellar: false, showPropertyListed: false, showHomeOccupancy: true, showRuralPursuit: false, showWallRoofType: false, showWallMaterial: false, showRoofMaterial: false
            };
        case actions.SHOW_RURAL_PURSUIT_REFERRAL:
            return {
                // eslint-disable-next-line max-len
                ...state, showAboutTheBuildingContainer: false, showConstructionMaterialsContainer: false, showOutsideYourHomeContainer: true, showHomeType: false, showTypeOfProperty: false, showBedrooms: false, showYearBuilt: false, showBasementOrCellar: false, showPropertyListed: false, showHomeOccupancy: false, showRuralPursuit: true, showWallRoofType: false, showWallMaterial: false, showRoofMaterial: false
            };
        case actions.SHOW_WALL_MATERIAL_REFERRAL:
            return {
                // eslint-disable-next-line max-len
                ...state, showAboutTheBuildingContainer: false, showConstructionMaterialsContainer: true, showOutsideYourHomeContainer: false, showHomeType: false, showTypeOfProperty: false, showBedrooms: false, showYearBuilt: false, showBasementOrCellar: false, showPropertyListed: false, showHomeOccupancy: false, showRuralPursuit: false, showWallRoofType: false, showWallMaterial: true, showRoofMaterial: false
            };
        case actions.SHOW_ROOF_MATERIAL_REFERRAL:
            return {
                // eslint-disable-next-line max-len
                ...state, showAboutTheBuildingContainer: false, showConstructionMaterialsContainer: true, showOutsideYourHomeContainer: false, showHomeType: false, showTypeOfProperty: false, showBedrooms: false, showYearBuilt: false, showBasementOrCellar: false, showPropertyListed: false, showHomeOccupancy: false, showRuralPursuit: false, showWallRoofType: false, showWallMaterial: false, showRoofMaterial: true
            };
        case actions.SHOW_CONSTRUCTION_REFERRAL_WALL:
            return {
                // eslint-disable-next-line max-len
                ...state, showAboutTheBuildingContainer: false, showConstructionMaterialsContainer: true, showOutsideYourHomeContainer: false, showHomeType: false, showTypeOfProperty: false, showBedrooms: false, showYearBuilt: false, showBasementOrCellar: false, showPropertyListed: false, showHomeOccupancy: false, showRuralPursuit: false, showWallRoofType: true, showWallMaterial: true, showRoofMaterial: false
            };
        case actions.SHOW_CONSTRUCTION_REFERRAL_ROOF:
            return {
                // eslint-disable-next-line max-len
                ...state, showAboutTheBuildingContainer: false, showConstructionMaterialsContainer: true, showOutsideYourHomeContainer: false, showHomeType: false, showTypeOfProperty: false, showBedrooms: false, showYearBuilt: false, showBasementOrCellar: false, showPropertyListed: false, showHomeOccupancy: false, showRuralPursuit: false, showWallRoofType: true, showWallMaterial: true, showRoofMaterial: true
            };
        case actions.SHOW_INITIAL_STATE:
            return {
                ...state, ...initialState
            };
        default:
            throw Error();
    }
};

function PropertyDetailsPage(props) { /* NOSONAR: pure declarative usage */
    const { wizardData: submissionVM, updateWizardData } = props;
    const translator = useContext(TranslatorContext);
    const viewModelService = useContext(ViewModelServiceContext);
    const { authHeader } = useAuthentication();
    const { LoadSaveService } = useDependencies('LoadSaveService');
    const [isPageInitialized, setPageInitialized] = useState(false);
    const [isReferralVisible, setIsReferralVisible] = useState(false);
    const [isYearbuiltReferal, setIsYearbuiltReferal] = useState(false);
    const [isPropertyListedReferal, setIsPropertyListedReferal] = useState(false);
    const [state, dispatch] = useReducer(reducer, initialState);
    const aboutOutsideHomeRef = useRef();
    const aboutBuildingContainerRef = useRef();
    const propertyListedRef = useRef();
    const yearBuiltContainerRef = useRef();
    const {
        setIsAppLoading
    } = useContext(AppContext);

    const {
        onValidate,
        initialValidation,
        isComponentValid,
        registerInitialComponentValidation,
        registerComponentValidation
    } = useValidation('PropertyDetailsPage');
    const { handleError, handleNotBlockingError } = useErrorHandler();
    const { cleanNotUpdatedCoverages } = useCleanPayload();
    const YEAR_OR_EMPTY_REGEXP = '^[1-2]{1}[0-9]{0,3}$|^$';
    const YEAR_BUILT_MIN = 1000;
    const YEAR_BUILT_MAX = 1839;
    const YEAR_BUILT_MAX_TO_SHOW_BASEMENT = 1946;
    const IS_REFERRED_OFFLINE_PATH = 'lobData.homeLine.coverables.homhomeProperty.isReferedOffline';
    const PROP_DETAILS_PATH = 'lobData.homeLine.coverables.homhomeProperty.homhomePropertyDetails';
    const HOME_TYPE_PATH = 'lobData.homeLine.coverables.homhomeProperty.homhomePropertyDetails.homeType';
    const TYPE_OF_PROPERTY_PATH = 'lobData.homeLine.coverables.homhomeProperty.homhomePropertyDetails.typeOfProperty';
    const PROPERTY_YEAR_BUILT_PATH = 'lobData.homeLine.coverables.homhomeProperty.homhomePropertyDetails.propertyYearBuilt';
    const PROPERTY_YEAR_BUILT_RANGE_PATH = 'lobData.homeLine.coverables.homhomeProperty.homhomePropertyDetails.propertyYearBuiltRange';
    const WALL_CONSTRUCTION_PATH = 'lobData.homeLine.coverables.homhomeProperty.homhomePropertyDetails.wallConstruction';
    const ROOF_CONSTRUCTION_TYPE_PATH = 'lobData.homeLine.coverables.homhomeProperty.homhomePropertyDetails.roofConstructionType';
    const PROPERTY_LISTED_PATH = 'lobData.homeLine.coverables.homhomeProperty.homhomePropertyDetails.propertyListed';
    const BRICK_WALLS_AND_TILE_ROOF_PATH = 'lobData.homeLine.coverables.homhomeProperty.homhomePropertyDetails.brickWallsAndTileRoof';
    const HOM_PROP_BUILT_YEAR_BASEMENT_CELLAR_RANGES = ['1920 to 1945', '1840 to 1919', '1721 to 1839', '1600 to 1720', '1000 to 1599'];
    const OTHER_KEY = 'Other';
    const OTHER_PROPERTY_KEY = 'other';
    const BRICK_KEY = 'Brick';
    const TILE_KEY = 'Tile';
    const HOLIDAYWEEKEND_USE_ONLY_KEY = 'HolidayweekendUseOnly';
    const OTHER_DIGITAL_KEY = 'OtherDigital';
    const FLAT_MAISONETTE_BASEMENT_KEY = 'FlatMaisonetteBasement';
    const FLAT_MAISONETTE_TOP_FLOOR_KEY = 'FlatMaisonetteTopFloor';
    const HOUSE_KEY = 'house';
    const FLAT_KEY = 'flat';
    const BUNGALOW_KEY = 'bungalow';
    const THATCH_REED = 'ThatchReed';
    const THATCH_FIBRE = 'ThatchFibre';
    const { pushFormStepInfo, pushFormStepErrorInfo } = useTagManager();
    const producerDesc = _.get(submissionVM.value, 'baseData.producerDetails_NFUM.producerCodeDescription', '');
    const producerTele = _.get(submissionVM.value, 'baseData.producerDetails_NFUM.producerCodeTelephone', '');
    const isBespoke = _.get(submissionVM.value, 'baseData.producerDetails_NFUM.isBespoke', '');
    const showAgencyDetails = _.get(submissionVM.value, 'baseData.producerDetails_NFUM.showProducerDetails', '');

    useEffect(() => {
        const { isSkipping } = props;
        if (isSkipping) {
            initialValidation().then((skip) => {
                if (!skip) {
                    pushFormStepInfo(submissionVM, QB_STEPS.PROPERTY_DETAILS);
                }
            });
        } else {
            pushFormStepInfo(submissionVM, QB_STEPS.PROPERTY_DETAILS);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const skipWhenRetrieved = useCallback(() => {
        return _.get(submissionVM, 'lobData.homeLine.coverables.homhomeProperty.homhomePropertyDetails.aspects.subtreeValid') === true
            || _.get(submissionVM.value, 'baseData.periodStatus') === 'Quoted';
    }, [submissionVM]);

    const getFormValidity = useCallback(() => {
        const propertyDetailsVM = _.get(submissionVM, 'lobData.homeLine.coverables.homhomeProperty.homhomePropertyDetails');
        const isFormValid = propertyDetailsVM.aspects.valid
            && propertyDetailsVM.aspects.subtreeValid;
        return isFormValid;
    }, [submissionVM]);

    useEffect(() => {
        registerInitialComponentValidation(skipWhenRetrieved);
        registerComponentValidation(getFormValidity);
    }, [
        skipWhenRetrieved,
        registerInitialComponentValidation,
        getFormValidity,
        registerComponentValidation
    ]);

    useEffect(() => {
        if (_.isEmpty(_.get(submissionVM.value, PROP_DETAILS_PATH))) {
            _.unset(submissionVM.value, PROP_DETAILS_PATH);
            updateWizardData(submissionVM);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const submitReferralMessageData = useCallback(async () => {
        try {
            await LoadSaveService.updateDraftSubmission(
                submissionVM.value,
                authHeader
            );
        } catch (error) {
            handleNotBlockingError(error);
            return false;
        }
        return submissionVM;
    }, [submissionVM, handleNotBlockingError, LoadSaveService, authHeader]);

    const stickToOwnPos = useCallback((location, showorHide) => {
        if (location === 'outsideHome') {
            const outsidePos = aboutOutsideHomeRef.current.getBoundingClientRect();
            if (aboutBuildingContainerRef && aboutOutsideHomeRef) {
                const buldingHt = aboutBuildingContainerRef?.current?.offsetHeight || 0;
                const outsideHt = aboutOutsideHomeRef.current.offsetHeight;
                return { x: outsidePos.left, y: buldingHt + outsideHt };
            }
            if (!aboutBuildingContainerRef && aboutOutsideHomeRef) {
                return { x: outsidePos.left, y: aboutOutsideHomeRef.current.offsetHeight };
            }
        }

        if (location === 'propertyListed') {
            const propertyListedPos = propertyListedRef.current.getBoundingClientRect();
            const buldingHt = aboutBuildingContainerRef?.current?.offsetHeight || 0;
            if (showorHide === 'show') {
                return { x: propertyListedPos.left, y: buldingHt };
            }
            return { x: propertyListedPos.left, y: buldingHt * 4 };
        }
        if (location === 'yearBuilt') {
            const yearBuiltContainerPos = yearBuiltContainerRef.current.getBoundingClientRect();
            const buldingHt = aboutBuildingContainerRef?.current?.offsetHeight || 0;
            if (showorHide === 'hide') {
                return { x: yearBuiltContainerPos.left, y: buldingHt };
            }
            return { x: yearBuiltContainerPos.left, y: yearBuiltContainerPos.top };
        }
        return { x: 0, y: aboutBuildingContainerRef?.current?.offsetHeight || 0 };
    }, []);

    const showReferral = useCallback(async (type, location) => {
        setIsReferralVisible(true);
        dispatch({ type });
        _.set(submissionVM, IS_REFERRED_OFFLINE_PATH, true);
        if (location === 'outsideHome' || location === 'propertyListed') {
            const { x, y } = stickToOwnPos(location, 'show');
            window.scrollTo(x, y);
        }
        if (location === 'propertyListed') {
            setIsPropertyListedReferal(true);
        }
        if (location === 'yearBuilt') {
            setIsYearbuiltReferal(true);
        }
        updateWizardData(submissionVM);
        await submitReferralMessageData();
    }, [stickToOwnPos, submissionVM, submitReferralMessageData, updateWizardData]);

    const hideReferral = useCallback((location) => {
        setIsReferralVisible(false);
        dispatch({ type: actions.SHOW_INITIAL_STATE });
        _.set(submissionVM, IS_REFERRED_OFFLINE_PATH, false);
        if (location === 'outsideHome') {
            const { x, y } = stickToOwnPos(location, 'hide');
            window.scrollTo(x, y);
        }
        if (location === 'propertyListed') {
            if (isPropertyListedReferal) {
                const { x, y } = stickToOwnPos(location, 'hide');
                window.scrollTo(x, y);
                setIsPropertyListedReferal(false);
            }
        }
        if (location === 'yearBuilt') {
            if (isYearbuiltReferal) {
                const { x, y } = stickToOwnPos(location, 'hide');
                window.scrollTo(x, y);
                setIsYearbuiltReferal(false);
            }
        }
        updateWizardData(submissionVM);
    }, [isPropertyListedReferal,
        isYearbuiltReferal, stickToOwnPos, submissionVM, updateWizardData]);

    useEffect(() => {
        if (_.isEmpty(_.get(submissionVM.value, 'lobData.homeLine.coverables.homhomeProperty.homhomePropertyDetails'))) {
            _.set(submissionVM.value, 'lobData.homeLine.coverables.homhomeProperty.homhomePropertyDetails', {});
            updateWizardData(submissionVM);
        }
        setPageInitialized(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const isYearFormat = (value) => {
        const regex = new RegExp(YEAR_OR_EMPTY_REGEXP);
        return regex.test(value);
    };

    const handleHomeTypeChange = useCallback((value, path) => {
        _.set(submissionVM, path, value);
        _.set(submissionVM.value, TYPE_OF_PROPERTY_PATH, undefined);
        if (value === OTHER_PROPERTY_KEY) {
            showReferral(actions.SHOW_TYPE_OF_PROPERTY_REFERRAL);
        } else {
            hideReferral();
        }
        updateWizardData(submissionVM);
    }, [
        submissionVM,
        updateWizardData,
        hideReferral,
        showReferral
    ]);

    const handleYearBuiltChange = useCallback((propertyBuiltYear, path) => {
        if (!isYearFormat(propertyBuiltYear)) return;
        if (propertyBuiltYear === '') {
            _.set(submissionVM, 'lobData.homeLine.coverables.homhomeProperty.homhomePropertyDetails.propertyYearBuiltRange', undefined);
        }
        if (propertyBuiltYear >= YEAR_BUILT_MIN && propertyBuiltYear <= YEAR_BUILT_MAX) {
            showReferral(actions.SHOW_YEAR_BUILT_REFERRAL, 'yearBuilt');
        } else {
            hideReferral('yearBuilt');
        }
        _.set(submissionVM, path, propertyBuiltYear);
        updateWizardData(submissionVM);
    }, [submissionVM, updateWizardData, showReferral, hideReferral]);

    const handleTypeOfPropertyChange = useCallback((typeOfProperty, path) => {
        _.set(submissionVM, path, typeOfProperty);
        if ([FLAT_MAISONETTE_BASEMENT_KEY, FLAT_MAISONETTE_TOP_FLOOR_KEY]
            .includes(typeOfProperty)) {
            showReferral(actions.SHOW_TYPE_OF_PROPERTY_REFERRAL);
        } else {
            hideReferral();
        }
        updateWizardData(submissionVM);
    }, [submissionVM, updateWizardData, showReferral, hideReferral]);

    const handlePropertyListedChange = useCallback((isPropertyListed, path) => {
        _.set(submissionVM.value, path, isPropertyListed);
        if (isPropertyListed === OTHER_DIGITAL_KEY) {
            showReferral(actions.SHOW_PROPERTY_LISTED_REFERRAL, 'propertyListed');
        } else {
            hideReferral('propertyListed');
        }
        updateWizardData(submissionVM);
    }, [submissionVM, updateWizardData, showReferral, hideReferral]);

    const handleHomeOccupancyChange = useCallback((homeOccupancy, path) => {
        _.set(submissionVM.value, path, homeOccupancy);
        if (homeOccupancy === HOLIDAYWEEKEND_USE_ONLY_KEY) {
            showReferral(actions.SHOW_HOME_OCCUPANCY_REFERRAL);
        } else {
            hideReferral();
        }
        updateWizardData(submissionVM);
    }, [submissionVM, updateWizardData, showReferral, hideReferral]);

    const handleOtherLandOwnedChange = useCallback((isOtherLandOwned, path) => {
        const notIsOtherLandOwned = !isOtherLandOwned;
        _.set(submissionVM.value, path, notIsOtherLandOwned);
        if (notIsOtherLandOwned === true) {
            showReferral(actions.SHOW_RURAL_PURSUIT_REFERRAL, 'outsideHome');
        } else {
            hideReferral('outsideHome');
        }
        updateWizardData(submissionVM);
    }, [submissionVM, updateWizardData, showReferral, hideReferral]);

    const handleWallRoofTypeChange = useCallback((isWallRoofType, path) => {
        _.set(submissionVM, path, isWallRoofType);
        if (isWallRoofType === true) {
            _.set(submissionVM.value, WALL_CONSTRUCTION_PATH, BRICK_KEY);
            _.set(submissionVM.value, ROOF_CONSTRUCTION_TYPE_PATH, TILE_KEY);
            hideReferral();
        } else if (isWallRoofType === false) {
            _.unset(submissionVM.value, WALL_CONSTRUCTION_PATH);
            _.unset(submissionVM.value, ROOF_CONSTRUCTION_TYPE_PATH);
        }
        updateWizardData(submissionVM);
    }, [hideReferral, submissionVM, updateWizardData]);

    const handleWallMaterialChange = useCallback((wallMaterial, path) => {
        _.set(submissionVM, path, wallMaterial);
        if (wallMaterial === OTHER_KEY) {
            showReferral(actions.SHOW_CONSTRUCTION_REFERRAL_WALL);
        } else {
            const roofType = _.get(submissionVM.value, ROOF_CONSTRUCTION_TYPE_PATH);
            if (roofType !== OTHER_KEY) {
                hideReferral();
            }
        }
        updateWizardData(submissionVM);
    }, [submissionVM, updateWizardData, showReferral, hideReferral]);

    const handleRoofMaterialChange = useCallback((roofMaterial, path) => {
        _.set(submissionVM, path, roofMaterial);
        if (roofMaterial === OTHER_KEY ||
            roofMaterial === THATCH_REED ||
            roofMaterial === THATCH_FIBRE) {
            showReferral(actions.SHOW_CONSTRUCTION_REFERRAL_ROOF);
        } else {
            hideReferral();
        }
        updateWizardData(submissionVM);
    }, [submissionVM, updateWizardData, showReferral, hideReferral]);

    const handleBedroomsChange = useCallback((bedrooms, path) => {
        if (bedrooms === OTHER_DIGITAL_KEY) {
            showReferral(actions.SHOW_BEDROOMS_REFERRAL);
        } else {
            hideReferral();
        }
        _.set(submissionVM, path, bedrooms);
        updateWizardData(submissionVM);
    }, [submissionVM, updateWizardData, showReferral, hideReferral]);

    const onNext = useCallback(async () => {
        setIsAppLoading(true);
        _.set(submissionVM, 'lobData.value.homeLine.coverables.homhomeProperty.homhomePropertyConstruction.isValidationNotRequired', true);
        if (_.get(submissionVM, 'lobData.homeLine.coverables.homhomeProperty.aspects.subtreeValid') === false) {
            const homhomePropertyDetails = _.get(submissionVM, 'lobData.value.homeLine.coverables.homhomeProperty.homhomePropertyDetails');
            _.set(submissionVM, 'lobData.value.homeLine.coverables.homhomeProperty', { homhomePropertyDetails });
        }
        cleanNotUpdatedCoverages(submissionVM);
        try {
            submissionVM.value = await LoadSaveService.updateDraftSubmission(
                submissionVM.value,
                authHeader
            );
        } catch (error) {
            setIsAppLoading(false);
            pushFormStepErrorInfo(submissionVM, QB_STEPS.PROPERTY_DETAILS, error);
            handleError(error, submissionVM.value.quoteID);
            return false;
        }
        setIsAppLoading(false);
        return submissionVM;
    }, [
        LoadSaveService,
        authHeader,
        submissionVM,
        handleError,
        pushFormStepErrorInfo,
        cleanNotUpdatedCoverages,
        setIsAppLoading
    ]);

    const isYearBuiltRangeVisible = _.get(submissionVM.value, PROPERTY_YEAR_BUILT_RANGE_PATH)
        !== undefined && _.get(submissionVM.value, PROPERTY_YEAR_BUILT_PATH) === undefined;

    const isBasementOrCellarVisible = [HOUSE_KEY, BUNGALOW_KEY, FLAT_KEY]
        .includes(_.get(submissionVM.value, HOME_TYPE_PATH))
        && (_.get(submissionVM.value, PROPERTY_YEAR_BUILT_PATH) <= YEAR_BUILT_MAX_TO_SHOW_BASEMENT
            || HOM_PROP_BUILT_YEAR_BASEMENT_CELLAR_RANGES
                .includes(_.get(submissionVM.value, PROPERTY_YEAR_BUILT_RANGE_PATH)));

    const getTypesOfAvailableValues = (valueType, filterType, isBespokeHome) => {
        const availableValueTypelist = viewModelService.getTypelistNFUM('pc', `typekey.${valueType}`);
        if (isBespokeHome) {
            return availableValueTypelist.codes.map((typecode) => {
                return {
                    code: typecode.code,
                    name: translator({
                        id: typecode.name,
                        defaultMessage: typecode.code
                    })
                };
            });
        }
        return availableValueTypelist.filters.find((value) => value.name === `${filterType}`).codes.map((typecode) => {
            return {
                code: typecode.code,
                name: translator({
                    id: typecode.name,
                    defaultMessage: typecode.code
                })
            };
        });
    };
    const overrideProps = {
        aboutTheBuildingContainer: {
            visible: state.showAboutTheBuildingContainer,
            ref: aboutBuildingContainerRef
        },
        aboutOutsideYourHomeContainer: {
            visible: state.showOutsideYourHomeContainer,
            ref: aboutOutsideHomeRef
        },
        homeType: {
            visible: state.showHomeType,
            value: _.get(submissionVM.value, HOME_TYPE_PATH),
            onValueChange: handleHomeTypeChange,
            tooltip: {
                text: translator(messages.homeTypeTooltip)
            }
        },
        typeOfProperty: {
            visible: state.showTypeOfProperty
                && (_.get(submissionVM.value, HOME_TYPE_PATH) !== undefined
                && _.get(submissionVM.value, HOME_TYPE_PATH) !== OTHER_PROPERTY_KEY),
            value: _.get(submissionVM.value, TYPE_OF_PROPERTY_PATH),
            onValueChange: handleTypeOfPropertyChange
        },
        bedroomsContainer: {
            visible: state.showBedrooms
        },
        bedrooms: {
            onValueChange: handleBedroomsChange
        },
        yearBuiltContainer: {
            visible: state.showYearBuilt,
            ref: yearBuiltContainerRef
        },
        yearBuilt: {
            visible: !isYearBuiltRangeVisible,
            onValueChange: handleYearBuiltChange,
        },
        yearBuiltLabel: {
            tooltip: {
                text: translator(messages.yearBuiltTooltip)
            }
        },
        yearBuiltRange: {
            visible: isYearBuiltRangeVisible
        },
        basementOrCellar: {
            visible: state.showBasementOrCellar && isBasementOrCellarVisible
        },
        propertyListed: {
            visible: state.showPropertyListed,
            value: _.get(submissionVM.value, PROPERTY_LISTED_PATH),
            onValueChange: handlePropertyListedChange,
            tooltip: {
                text: translator(messages.propertyListedTooltip)
            },
            ref: propertyListedRef
        },
        homeOccupancy: {
            visible: state.showHomeOccupancy,
            availableValues: getTypesOfAvailableValues('AvgPropertyOccupation_NFUM', 'NotPrivateHolidayHome', isBespoke),
            onValueChange: handleHomeOccupancyChange,
            tooltip: {
                text: translator(messages.HomeOccupancyTooltip)
            }
        },
        ruralPursuit: {
            visible: state.showRuralPursuit
        },
        aboutBuildingConfirmRadioButton: {
        },
        otherLandOwned: {
            // nfum: hack for accessibility issue #742821 - 1) true 2) false options
            value: _.get(submissionVM.value, 'lobData.homeLine.coverables.homhomeProperty.homhomePropertyDetails.otherLandOwned') === undefined
                ? undefined
                : !_.get(submissionVM.value, 'lobData.homeLine.coverables.homhomeProperty.homhomePropertyDetails.otherLandOwned'),
            visible: state.showRuralPursuit,
            onValueChange: handleOtherLandOwnedChange
        },
        constructionMaterialsContainer: {
            visible: state.showConstructionMaterialsContainer
        },
        wallRoofType: {
            visible: state.showWallRoofType,
            onValueChange: handleWallRoofTypeChange,
            tooltip: {
                text: translator(messages.wallRoofTypeTooltip)
            }
        },
        wallMaterial: {
            visible: state.showWallMaterial
                && _.get(submissionVM.value, BRICK_WALLS_AND_TILE_ROOF_PATH) === false,
            onValueChange: handleWallMaterialChange,
            tooltip: {
                text: translator(messages.wallMaterialTooltip)
            }
        },
        roofMaterial: {
            visible: state.showRoofMaterial
                && _.get(submissionVM.value, BRICK_WALLS_AND_TILE_ROOF_PATH) === false,
            onValueChange: handleRoofMaterialChange,
            tooltip: {
                text: translator(messages.RoofMaterialTooltip)
            }
        },
        referralMessageContainer: {
            visible: isReferralVisible
        },
        referralMessage: {
            title: translator(messages.referralTitle),
            description: translator(messages.referralDescription),
            paragraph1: translator(messages.getInTouchDescription),
            producerDesc: producerDesc,
            producerTele: producerTele,
            isBespoke: isBespoke,
            showAgencyDetails: showAgencyDetails
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveComponentMap: { referralMessage: ReferralMessage },
    };

    if (!isPageInitialized) {
        return null;
    }

    return (
        <WizardPage
            onNext={onNext}
            skipWhen={initialValidation}
            disableNext={!isComponentValid
                || _.get(submissionVM.value, IS_REFERRED_OFFLINE_PATH) === true}
            showPrevious
            showCancel={false}
            nextLabel={commonMessages.continue}
            previousLabel={commonMessages.back}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                classNameMap={resolvers.resolveClassNameMap}
                componentMap={resolvers.resolveComponentMap}
                onValidationChange={onValidate}
            />
        </WizardPage>
    );
}

PropertyDetailsPage.propTypes = wizardProps;
export default PropertyDetailsPage;
