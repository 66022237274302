import React, {
    useState, useCallback, useContext, useEffect, useRef
} from 'react';
import moment from 'moment';
import _ from 'lodash';
import { Link } from '@jutro/components';
import { TranslatorContext } from '@jutro/locale';
import { WizardPage, wizardProps } from 'gw-portals-wizard-react';
import { ViewModelServiceContext, ViewModelForm } from 'gw-portals-viewmodel-react';
import { useAuthentication } from 'gw-digital-auth-react';
import { useValidation } from 'gw-portals-validation-react';
import { useDependencies } from 'gw-portals-dependency-react';
import { AddressSearch } from 'nfum-capability-address-react';
import {
    useErrorHandler,
    QB_STEPS,
    CONTENTS_ONLY,
    AppContext
} from 'nfum-portals-utils-react';
import useCleanPayload from '../../hooks/useCleanPayload';
import useTagManager from '../../hooks/useTagManager';
import NGHContext from '../../NGHContext';
import styles from './YourDetailsPage.module.scss';
import messages from './YourDetailsPage.messages';
import metadata from './YourDetailsPage.metadata.json5';
import commonMessages from '../../NGHWizard.messages';
import OccupationLookup from '../../components/OccupationLookup/OccupationLookup';
import ClaimsHistory from './ClaimsHistory/ClaimsHistory';

function YourDetailsPage(props) { /* NOSONAR: pure declarative usage */
    const MAX_AGE = 120;
    const MIN_AGE = 16;
    const viewModelService = useContext(ViewModelServiceContext);
    const { wizardData: submissionVM, updateWizardData } = props;
    const translator = useContext(TranslatorContext);
    const { authHeader } = useAuthentication();
    const {
        initialValidation,
        onValidate,
        isComponentValid,
        registerInitialComponentValidation,
        registerComponentValidation
    } = useValidation('YourDetailsPage');
    const { LoadSaveService } = useDependencies('LoadSaveService');
    const { handleError } = useErrorHandler();
    const [data, setData] = useState({
        initialCoverageContent: true,
        propertyStatus: '',
        showErrorMessage: false,
        disableNext: true,
        documentsInAlternateFormat: false
    });
    const rentedStatusKeys = Object.freeze({
        RENTED_FULL_REPAIR_LEASE: 'RentedFullRepairingLease',
        RENTED_FURNISHED: 'RentedFurnished',
        RENTED_UNFURNISHED: 'RentedUnfurnished'
    });
    const leaseHoldStatusKeys = Object.freeze({
        LEASE_HOLD_OUTRIGHT: 'LeaseHolderOwnedOutrightByYou',
        LEASE_HOLD_MORTGAGE: 'LeaseHolderOwnedWithAMortgageByYou',
    });
    const quoteID = _.get(submissionVM, 'quoteID');
    const ref = useRef(null);
    const disableYourDetailForPrePopulateData = _.get(submissionVM.value, 'baseData.accountHolder.prePopulateData') || false;
    const propertyStatusValue = _.get(submissionVM.value, 'lobData.homeLine.coverables.homhomeProperty.homhomeYourDetails.propertyStatus');
    const { setIsExistingCustomer } = useContext(NGHContext);
    setIsExistingCustomer(false); // TODO: along with CRM Search US
    const { pushFormStepInfo, pushFormStepErrorInfo, pushLinkClickInfo } = useTagManager();
    const MAX_PERIOD_START_DATE_DAYS_SHIFT = 30;
    const minDate = moment();
    const maxDate = moment().add(MAX_PERIOD_START_DATE_DAYS_SHIFT, 'days');
    const [isYourDetailsValid, setIsYourDetailsValid] = useState(false);
    const [disableYourDetailsSec, setDisableYourDetailsSec] = useState(false);
    const { cleanNotUpdatedCoverages } = useCleanPayload();
    const producerDesc = _.get(submissionVM.value, 'baseData.producerDetails_NFUM.producerCodeDescription', '');
    const producerTele = _.get(submissionVM.value, 'baseData.producerDetails_NFUM.producerCodeTelephone', '');
    const isBespoke = _.get(submissionVM.value, 'baseData.producerDetails_NFUM.isBespoke', '');
    const showAgencyDetails = _.get(submissionVM.value, 'baseData.producerDetails_NFUM.showProducerDetails', '');
    const {
        setIsAppLoading
    } = useContext(AppContext);
    const PHONE_REGEXP = '^0[1-6][0-9]{8,10}$';
    const MOBILE_REGEX = '^07[0-9]{9}$';

    useEffect(() => {
        const { isSkipping } = props;
        if (isSkipping) {
            initialValidation().then((skip) => {
                if (!skip) {
                    pushFormStepInfo(submissionVM, QB_STEPS.YOUR_DETAILS);
                }
            });
        } else {
            pushFormStepInfo(submissionVM, QB_STEPS.YOUR_DETAILS);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        // as we need to save 'notSpecified_NFUM' as null on entity level => we assume that
        // if quote is created and this field is empty then it should be set back to 'Other' on UI
        if (quoteID.value && _.get(submissionVM, 'baseData.accountHolder.value.prefix') === undefined) {
            _.set(submissionVM, 'baseData.accountHolder.value.prefix', 'notSpecified_NFUM');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getTypesOfAvailableValues = (valueType, filterType) => {
        const availableValueTypelist = viewModelService.getTypelistNFUM('pc', `typekey.${valueType}`);
        return availableValueTypelist.filters.find((value) => value.name === `${filterType}`).codes.map((typecode) => {
            if (typecode.code === 'notSpecified_NFUM') {
                return {
                    code: typecode.code,
                    name: translator({
                        id: `quoteandbind.ngh.views.your-details.${typecode.code}`,
                        defaultMessage: 'Other'
                    })
                };
            }
            return {
                code: typecode.code,
                name: translator({
                    id: typecode.name,
                    defaultMessage: typecode.code
                })
            };
        });
    };

    const getOtherTypesOfTitle = (valueType, selectedCode) => {
        const availableValueTypelist = viewModelService.getTypelistNFUM('pc', `typekey.${valueType}`);
        if (selectedCode && availableValueTypelist.getCode(selectedCode).name) {
            return translator({
                id: availableValueTypelist.getCode(selectedCode).name,
                defaultMessage: availableValueTypelist.getCode(selectedCode).name
            });
        }
        return translator({
            id: `quoteandbind.ngh.views.your-details.${selectedCode}`,
            defaultMessage: 'Other'
        });
    };

    const checkIfPossibleToContinue = useCallback(() => {
        return _.get(submissionVM, 'baseData.aspects.subtreeValid') === true
            && _.get(submissionVM, 'lobData.homeLine.coverables.homhomeProperty.homhomeYourDetails.aspects.subtreeValid') === true
            && _.get(submissionVM, 'lobData.homeLine.priorClaims_NFUM.aspects.valid') === true
            && _.get(submissionVM, 'lobData.homeLine.priorClaims_NFUM.aspects.subtreeValid') === true
            && _.get(submissionVM, 'lobData.homeLine.isPriorClaim_NFUM.aspects.valid') === true;
    }, [submissionVM]);

    const checkIfPossibleToSkipWhenRetrieved = useCallback(() => {
        return checkIfPossibleToContinue()
            || _.get(submissionVM.value, 'baseData.periodStatus') === 'Quoted';
    }, [submissionVM, checkIfPossibleToContinue]);

    useEffect(() => {
        registerComponentValidation(checkIfPossibleToContinue);
        registerInitialComponentValidation(checkIfPossibleToSkipWhenRetrieved);
    }, [
        checkIfPossibleToSkipWhenRetrieved,
        registerInitialComponentValidation,
        checkIfPossibleToContinue,
        registerComponentValidation
    ]);

    const getMidnightFromMomentDate = useCallback((date) => {
        return date.toDate().setHours(0, 0, 0, 0);
    }, []);

    const onEffectiveDateChange = useCallback((startDate) => {
        const isStartDateEmpty = startDate?.year === undefined
            && startDate?.month === undefined
            && startDate?.day === undefined;
        if (isStartDateEmpty) {
            return;
        }
        const periodStartDate = new Date(startDate.year, startDate.month, startDate.day)
            .setHours(0, 0, 0, 0);
        const minimumDate = getMidnightFromMomentDate(minDate);
        const maximumDate = getMidnightFromMomentDate(maxDate);
        const isStartDateValid = !!startDate.year
            && (startDate.month >= 0 && startDate.month <= 11)
            && !!startDate.day;
        const newSubmissionVM = viewModelService.clone(submissionVM);
        const minDateDTO = {
            day: minDate.date(),
            month: minDate.month(),
            year: minDate.year()
        };
        const maxDateDTO = {
            day: maxDate.date(),
            month: maxDate.month(),
            year: maxDate.year()
        };
        _.set(newSubmissionVM.value, 'baseData.minDate_NFUM', minDateDTO);
        _.set(newSubmissionVM.value, 'baseData.maxDate_NFUM', maxDateDTO);
        if (!isStartDateValid || periodStartDate < minimumDate || periodStartDate > maximumDate) {
            _.set(newSubmissionVM.value, 'baseData.periodStartDate', undefined);
        } else {
            _.set(newSubmissionVM.value, 'baseData.periodStartDate', startDate);
        }
        updateWizardData(newSubmissionVM);
    }, [
        updateWizardData,
        viewModelService,
        submissionVM,
        minDate,
        maxDate,
        getMidnightFromMomentDate
    ]);

    useEffect(() => {
        if (_.isEmpty(_.get(submissionVM,
            'lobData.homeLine.coverables.homhomeProperty.homhomeYourDetails.value'))) {
            submissionVM.lobData.homeLine.coverables.homhomeProperty.homhomeYourDetails = {};
            submissionVM.baseData.accountHolder.value.prefix = '';
            updateWizardData(submissionVM);
        }
        if (quoteID && quoteID.value) { setIsYourDetailsValid(true); }
        onEffectiveDateChange(_.get(submissionVM.value, 'baseData.periodStartDate'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (quoteID && quoteID.value) {
            setData({ ...data, initialCoverageContent: false });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [quoteID]);

    const handleUpdatingState = useCallback((nextState) => {
        setData({ ...data, ...nextState });
    }, [data]);

    const coveragePath = 'lobData.homeLine.coverables.homhomeProperty.homhomeYourDetails.homHomeCoverables';

    const checkIfContentsOnlyAvailable = useCallback((propertyStatus) => {
        return [
            rentedStatusKeys.RENTED_FURNISHED,
            rentedStatusKeys.RENTED_UNFURNISHED
        ].includes(propertyStatus);
    }, [rentedStatusKeys]);

    const checkIfRented = useCallback((propertyStatus) => {
        return [
            rentedStatusKeys.RENTED_FURNISHED,
            rentedStatusKeys.RENTED_UNFURNISHED,
            rentedStatusKeys.RENTED_FULL_REPAIR_LEASE
        ].includes(propertyStatus);
    }, [rentedStatusKeys]);

    const checkIfLease = useCallback((propertyStatus) => {
        return [
            leaseHoldStatusKeys.LEASE_HOLD_MORTGAGE,
            leaseHoldStatusKeys.LEASE_HOLD_OUTRIGHT,
            rentedStatusKeys.RENTED_FULL_REPAIR_LEASE
        ].includes(propertyStatus);
    }, [leaseHoldStatusKeys, rentedStatusKeys]);

    const openPrivacyPolicy = useCallback(() => {
        const url = 'https://www.nfumutual.co.uk/legal-information/privacy-policy/';
        pushLinkClickInfo(translator(messages.PrivacyPolicyInformationLink), url);
        window.open(url, '_blank');
    }, [pushLinkClickInfo, translator]);

    const privacyPolicyInfoLink = () => {
        return (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <Link
                onClick={() => openPrivacyPolicy()}
                target="_blank"
                id="Link"
                role="link"
            >
                {translator(messages.PrivacyPolicyInformationLink)}
            </Link>
        );
    };

    const onNext = useCallback(async () => {
        setIsAppLoading(true);
        _.unset(submissionVM.value, 'bindData');
        const otherOccupations = _.get(submissionVM.value, 'lobData.homeLine.coverables.homhomeProperty.homhomeYourDetails.otherOccupations');
        _.set(submissionVM.value, 'lobData.homeLine.coverables.homhomeProperty.homhomeYourDetails.otherOccupations', otherOccupations
            ?.filter((occ) => !_.isEmpty(occ)));
        if (_.get(submissionVM, 'lobData.homeLine.coverables.homhomeProperty.aspects.subtreeValid') === false) {
            const homhomeYourDetails = _.get(submissionVM, 'lobData.value.homeLine.coverables.homhomeProperty.homhomeYourDetails');
            _.set(submissionVM, 'lobData.value.homeLine.coverables.homhomeProperty', { homhomeYourDetails });
        }
        cleanNotUpdatedCoverages(submissionVM);
        if (quoteID.value) {
            /** #825143 Home property construction is captured in upcoming screen
             * and so the validation is removed to enable changing cover types for
             * the draft submission  */
            _.set(submissionVM, 'lobData.value.homeLine.coverables.homhomeProperty.homhomePropertyConstruction', { isValidationNotRequired: true });

            try {
                submissionVM.value = await LoadSaveService.updateDraftSubmission(
                    submissionVM.value,
                    authHeader
                );
            } catch (error) {
                setIsAppLoading(false);
                pushFormStepErrorInfo(submissionVM, QB_STEPS.YOUR_DETAILS, error);
                handleError(error, submissionVM.value.quoteID);
                return false;
            }
        } else {
            try {
                submissionVM.value = await LoadSaveService.createSubmissionNfum(
                    submissionVM.value,
                    authHeader
                );
            } catch (error) {
                setIsAppLoading(false);
                pushFormStepErrorInfo(submissionVM, QB_STEPS.YOUR_DETAILS, error);
                handleError(error, submissionVM.value.quoteID);
                return false;
            }
        }
        setIsAppLoading(false);
        return submissionVM;
    }, [
        LoadSaveService,
        authHeader,
        quoteID.value,
        submissionVM,
        handleError,
        pushFormStepErrorInfo,
        cleanNotUpdatedCoverages,
        setIsAppLoading
    ]);
    const isMobileNumber = useCallback((value) => {
        const reg = new RegExp(MOBILE_REGEX, 'i');
        return reg.test(value);
    }, []);
    const isPhoneFormat = useCallback((value) => {
        const regex = new RegExp(PHONE_REGEXP);
        return regex.test(value);
    }, []);

    const handlePhoneNumberChange = useCallback((value, path) => {
        const clonedSubmissionVm = _.clone(submissionVM);
        _.set(clonedSubmissionVm, path, value);
        updateWizardData(clonedSubmissionVm);
    }, [submissionVM, updateWizardData]);

    const addPrimaryPhoneType = useCallback((value) => {
        const clonedSubmissionVm = _.clone(submissionVM);
        if (isPhoneFormat(value)) {
            const homeNumberPath = 'baseData.accountHolder.homeNumber';
            _.set(clonedSubmissionVm, homeNumberPath, value);
            _.set(submissionVM, 'baseData.accountHolder.value.primaryPhoneType', 'home');
        }
        if (isMobileNumber(value)) {
            const homeNumberPath = 'baseData.accountHolder.cellNumber';
            _.set(clonedSubmissionVm, homeNumberPath, value);
            _.set(submissionVM, 'baseData.accountHolder.value.primaryPhoneType', 'mobile');
        }
        updateWizardData(clonedSubmissionVm);
    }, [isMobileNumber, isPhoneFormat, submissionVM, updateWizardData]);

    const writeName = useCallback((value, path) => {
        // #839935 replacing curly apostrophe into straight
        const modifiedValue = value.replaceAll("’", "'")
        _.set(submissionVM, path, modifiedValue);
        updateWizardData(submissionVM);
    }, [submissionVM, updateWizardData]);

    const yourHomeSection = useCallback(() => {
        if (ref.current) {
            ref.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, []);

    const isYourDetailsectionValid = useCallback(() => {
        setIsYourDetailsValid(true);
        setDisableYourDetailsSec(true);
        yourHomeSection();
    }, [setIsYourDetailsValid]);

    const showYourDetailsSec = useCallback(() => {
        setIsYourDetailsValid(false);
    });

    const handlePropertyStatusChange = useCallback((propertyStatus, path) => {
        const newSubmissionVM = viewModelService.clone(submissionVM);
        _.set(newSubmissionVM, path, propertyStatus);
        handleUpdatingState({
            initialCoverageContent: false,
            propertyStatus: propertyStatus,
            showErrorMessage: false
        });
        _.set(
            newSubmissionVM,
            coveragePath,
            checkIfContentsOnlyAvailable(propertyStatus)
                ? CONTENTS_ONLY
                : null
        );
        updateWizardData(newSubmissionVM);
    }, [
        handleUpdatingState,
        submissionVM,
        updateWizardData,
        viewModelService,
        checkIfContentsOnlyAvailable
    ]);

    const validateDateofBirth = useCallback(() => {
        const dob = _.get(submissionVM, 'baseData.accountHolder.value.dateOfBirth');
        if (dob) {
            const today = new Date();
            const birthDate = new Date(dob.year, dob.month, dob.day);
            let age = today.getFullYear() - birthDate.getFullYear();
            if (today.getFullYear() === birthDate.getFullYear()) {
                const monthDiff = today.getMonth() - birthDate.getMonth();
                if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
                    age = -age;
                }
            }
            if (age < MIN_AGE || age > MAX_AGE || birthDate.getTime() > Date.now()) {
                return true;
            }
            return false;
        }
        return true;
    }, [submissionVM]);

    const dateInputRole = document.querySelectorAll('.jut__SimpleDateField__container');
    dateInputRole.forEach((role) => {
        if (role) {
            role.setAttribute('role', 'group');
        }
    });

    const isQuoteID = !!quoteID.value;
    const overrideProps = {
        addressContainer: {
            setIsAppLoading,
            onAddressChanged: (newAddressVM) => {
                _.set(submissionVM, 'baseData.value.policyAddress', newAddressVM.value);
                updateWizardData(submissionVM);
            }
        },
        coverageType: {
            disabled: data.initialCoverageContent
                || propertyStatusValue === rentedStatusKeys.RENTED_FURNISHED
                || propertyStatusValue === rentedStatusKeys.RENTED_UNFURNISHED,
            value: _.get(submissionVM.value, 'lobData.homeLine.coverables.homhomeProperty.homhomeYourDetails.homHomeCoverables'),
            description: checkIfLease(propertyStatusValue)
                ? messages.coverTypeDescText.defaultMessage
                : null
        },
        coverageWarningText: {
            visible: data.showErrorMessage,
        },
        privacyPolicyInfo: {
            content: privacyPolicyInfoLink()
        },
        documentsAlternateFormat: {
            value: _.get(submissionVM.value, 'baseData.accountHolder.alternateFormat_NFUM'),
            disabled: disableYourDetailsSec || (quoteID.value && _.get(submissionVM.value, 'baseData.accountHolder.alternateFormat_NFUM') !== undefined),
        },
        documentsInFormat: {
            visible: submissionVM.value.baseData.accountHolder.alternateFormat_NFUM === true,
            disabled: disableYourDetailsSec
            || (quoteID.value
                && submissionVM.baseData.accountHolder.customerCareDocumentFormate_NFUM.value),
            availableValues: getTypesOfAvailableValues('CustCareDocFormatType_NFUM', 'CustCareDocFilter'),
        },
        propertyStatusType: {
            onValueChange: handlePropertyStatusChange
        },
        title: {
            availableValues: getTypesOfAvailableValues('NamePrefix', 'DigitalNamePrefixFilter'),
            disabled: disableYourDetailsSec || quoteID.value || disableYourDetailForPrePopulateData,
            visible: !disableYourDetailForPrePopulateData
        },
        otherTitle: {
            value: getOtherTypesOfTitle('NamePrefix', submissionVM.value.baseData.accountHolder.prefix),
            disabled: disableYourDetailsSec || quoteID.value || disableYourDetailForPrePopulateData,
            visible: disableYourDetailForPrePopulateData
        },
        firstName: {
            onValueChange: writeName,
            disabled: disableYourDetailsSec || quoteID.value || disableYourDetailForPrePopulateData
        },
        lastName: {
            onValueChange: writeName,
            disabled: disableYourDetailsSec || quoteID.value || disableYourDetailForPrePopulateData
        },
        workPhone: {
            visible: isQuoteID && _.get(submissionVM, 'baseData.value.accountHolder.primaryPhoneType') === 'work',
            disabled: disableYourDetailsSec || quoteID.value || disableYourDetailForPrePopulateData
        },
        mobilePhone: {
            visible: isQuoteID && _.get(submissionVM, 'baseData.value.accountHolder.primaryPhoneType') === 'mobile',
            disabled: disableYourDetailsSec || quoteID.value || disableYourDetailForPrePopulateData
        },
        homePhone: {
            visible: isQuoteID && _.get(submissionVM, 'baseData.value.accountHolder.primaryPhoneType') === 'home',
            disabled: disableYourDetailsSec || quoteID.value || disableYourDetailForPrePopulateData
        },
        mobileOrLandline: {
            visible: !isQuoteID,
            onValueChange: handlePhoneNumberChange,
            onBlur: addPrimaryPhoneType,
            disabled: disableYourDetailsSec || disableYourDetailForPrePopulateData
        },
        email: {
            tooltip: {
                text: translator(messages.emailTooltip)
            },
            disabled: disableYourDetailsSec || quoteID.value || disableYourDetailForPrePopulateData
        },
        dateOfBirth: {
            disabled: disableYourDetailsSec || quoteID.value || disableYourDetailForPrePopulateData,
            maxDate: new Date(),
            role: 'group',
            legend: translator(messages.DateOfBirth),
            className: 'labelMargin'
        },
        yourDetailsPrePopulateInfoContainer: {
            visible: disableYourDetailForPrePopulateData
        },
        effectiveDate: {
            tooltip: {
                text: translator(messages.EffectiveDateTooltip)
            },
            onBlur: onEffectiveDateChange,
            minDate: minDate.toDate(),
            maxDate: getMidnightFromMomentDate(maxDate)
        },
        occupationLookup: {
            wizardData: submissionVM,
            updateWizardData: updateWizardData,
            visible: isYourDetailsValid
        },
        yourDetailsContainer: {
            completeLabel: isYourDetailsValid ? translator(messages.complete) : null,
            IconOnClick: showYourDetailsSec,
        },
        declarationContainer: {
            completeLabel: isYourDetailsValid ? translator(messages.complete) : null,
            IconOnClick: showYourDetailsSec
        },
        documentsContainer: {
            completeLabel: isYourDetailsValid ? translator(messages.complete) : null,
            IconOnClick: showYourDetailsSec
        },
        continue1: {
            onClick: isYourDetailsectionValid,
            disabled: !(submissionVM.baseData.accountHolder.displayName.aspects.valid
                && submissionVM.baseData.accountHolder.firstName.aspects.valid
                && submissionVM.baseData.accountHolder.lastName.aspects.valid
                && submissionVM.baseData.accountHolder.emailAddress1.aspects.valid
                && submissionVM.baseData.accountHolder.dateOfBirth.aspects.valid
                && submissionVM.baseData.accountHolder.workNumber.aspects.valid
                && submissionVM.baseData.accountHolder.homeNumber.aspects.valid
                && submissionVM.baseData.accountHolder.value.prefix !== ''
                && ((submissionVM.value.baseData.accountHolder.alternateFormat_NFUM === false)
                || (submissionVM.value.baseData.accountHolder.alternateFormat_NFUM
                    && submissionVM.value.baseData.accountHolder.customerCareDocumentFormate_NFUM
                    !== undefined)))
                    || (disableYourDetailForPrePopulateData && validateDateofBirth())
        },
        yourDetailsContent: {
            visible: !isYourDetailsValid
        },
        documentContent: {
            visible: !isYourDetailsValid
        },
        declarationMessageContainer: {
            visible: !isYourDetailsValid
        },
        pageContainer: {
            ref: ref
        },
        yourhomeContent: {
            visible: isYourDetailsValid
        },
        claimsHistoryContent: {
            visible: isYourDetailsValid
        },
        yourCoverContent: {
            visible: isYourDetailsValid,
        },
        yourHomeContainer: {
            className: (quoteID.value || isYourDetailsValid) ? '' : styles.headerStyle
        },
        claimsHistoryContainer: {
            className: (quoteID.value || isYourDetailsValid) ? '' : styles.headerStyle
        },
        yourCoverContainer: {
            className: (quoteID.value || isYourDetailsValid) ? '' : styles.headerStyle
        },
        yourOccupationContainer: {
            className: (quoteID.value || isYourDetailsValid) ? '' : styles.headerStyle
        },
        referralMessageContainer: {
            visible: disableYourDetailForPrePopulateData && validateDateofBirth()
        },
        referralMessage: {
            title: translator(messages.referralTitle),
            description: translator(messages.referralDescription),
            producerDesc: producerDesc,
            producerTele: producerTele,
            isBespoke: isBespoke,
            showAgencyDetails: showAgencyDetails
        },
        prepopulationLabelContaner: {
            visible: disableYourDetailForPrePopulateData && !validateDateofBirth()
        },
        claimsHistory: {
            wizardData: submissionVM,
            updateWizardData: updateWizardData
        },
        tenantsInteriorTooltipText: {
            visible: !propertyStatusValue || checkIfRented(propertyStatusValue)
        },
        tenantsInteriorTooltipText1: {
            visible: !propertyStatusValue || checkIfRented(propertyStatusValue)
        }
    };
    const resolvers = {
        resolveClassNameMap: styles,
        resolveComponentMap: {
            occupationLookup: OccupationLookup,
            addressSearch: AddressSearch,
            claimsHistory: ClaimsHistory
        },
        resolveCallbackMap: {
            onValidate: onValidate
        }
    };

    return (
        <WizardPage
            onNext={onNext}
            disableNext={!isComponentValid}
            skipWhen={initialValidation}
            showPrevious={false}
            showCancel={false}
            nextLabel={commonMessages.continue}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                onValidationChange={onValidate}
                classNameMap={resolvers.resolveClassNameMap}
                componentMap={resolvers.resolveComponentMap}
                callbackMap={resolvers.resolveCallbackMap}
            />
        </WizardPage>
    );
}

YourDetailsPage.propTypes = wizardProps;
export default YourDetailsPage;
