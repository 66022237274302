import { defineMessages } from 'react-intl';

export default defineMessages({
    legalExpenseCoverTitle: {
        id: 'quoteandbind.ngh.views.policy-summary.additionalCovers.legalExpensesCover',
        defaultMessage: 'Personal Legal Expenses'
    },
    bicycleExtensionCoverTitle: {
        id: 'quoteandbind.ngh.views.policy-summary.additionalCovers.bicycleExtensionCoverTitle',
        defaultMessage: 'Cycling Protection'
    },
    homeEmergencyCoverTitle: {
        id: 'quoteandbind.ngh.views.policy-summary.additionalCovers.homeEmergencyCoverTitle',
        defaultMessage: 'Home Emergency'
    },
    added: {
        id: 'quoteandbind.ngh.views.policy-summary.additionalCovers.added',
        defaultMessage: 'Added'
    },
    included: {
        id: 'quoteandbind.ngh.views.policy-summary.additionalCovers.included',
        defaultMessage: 'Included'
    },
    notSelected: {
        id: 'quoteandbind.ngh.views.policy-summary.additionalCovers.notSelected',
        defaultMessage: 'Not selected'
    },
    personalLegalExpenses: {
        id: 'quoteandbind.ngh.views.policy-summary.additionalCovers.personalLegalExpenses',
        defaultMessage: 'Personal Legal Expenses'
    },
    personalLegalExpensesExtra: {
        id: 'quoteandbind.ngh.views.policy-summary.additionalCovers.personalLegalExpensesExtra',
        defaultMessage: 'Personal Legal Expenses Extra'
    },
    nonPropertySpecificCovers: {
        id: 'quoteandbind.ngh.views.policy-summary.additionalCovers.nonPropertySpecificCovers',
        defaultMessage: 'Non property specific covers'
    },
    includedAsStandard: {
        id: 'quoteandbind.ngh.views.policy-summary.additionalCovers.includedAsStandard',
        defaultMessage: 'Included as standard'
    },
    personalCyberCover: {
        id: 'quoteandbind.ngh.views.policy-summary.additionalCovers.personalCyberCover',
        defaultMessage: 'Personal Cyber Cover'
    },
    selected: {
        id: 'quoteandbind.ngh.views.policy-summary.additionalCovers.selected',
        defaultMessage: 'Selected'
    },
});
