import { defineMessages } from 'react-intl';

export default defineMessages({
    perYearAsterisk: {
        id: 'nfum.platform.nfumCostInfoBox.perYearAsterisk',
        defaultMessage: 'per year'
    },
    perMonthAsterisk: {
        id: 'nfum.platform.nfumCostInfoBox.perMonthAsterisk',
        defaultMessage: 'per month'
    },
    costBreakdown: {
        id: 'nfum.platform.nfumCostInfoBox.costBreakdown',
        defaultMessage: 'Annual cost breakdown'
    },
    insurancePremiumTax: {
        id: 'nfum.platform.nfumCostInfoBox.insurancePremiumTax',
        defaultMessage: '*Includes Insurance Premium Tax, where applicable'
    },
    yourPolicyNumber: {
        id: 'nfum.platform.nfumCostInfoBox.yourPolicyNumber',
        defaultMessage: 'Your policy number:'
    },
    yourPolicyStartDate: {
        id: 'nfum.platform.nfumCostInfoBox.yourPolicyStartDate',
        defaultMessage: 'Your policy start date:'
    },
    buildings: {
        id: 'nfum.platform.nfumCostInfoBox.buildings',
        defaultMessage: 'Buildings'
    },
    buildingsAccidentalDamage: {
        id: 'nfum.platform.nfumCostInfoBox.buildingsAccidentalDamage',
        defaultMessage: 'Buildings - Accidental damage'
    },
    contents: {
        id: 'nfum.platform.nfumCostInfoBox.contents',
        defaultMessage: 'Contents'
    },
    contentsAccidentalDamage: {
        id: 'nfum.platform.nfumCostInfoBox.contentsAccidentalDamage',
        defaultMessage: 'Contents - Accidental damage'
    },
    contentsAwayFromHome: {
        id: 'nfum.platform.nfumCostInfoBox.contentsAwayFromHome',
        defaultMessage: 'Contents away from the home'
    },
    includedAsStandard: {
        id: 'nfum.platform.nfumCostInfoBox.includedAsStandard',
        defaultMessage: 'Included as standard'
    },
    contentsAwayFromHomeNamedItemsOnly: {
        id: 'nfum.platform.nfumCostInfoBox.contentsAwayFromHomeNamedItemsOnly',
        defaultMessage: 'Contents away from the home (named item(s) only)'
    },
    cyclingProtection: {
        id: 'nfum.platform.nfumCostInfoBox.cyclingProtection',
        defaultMessage: 'Cycling protection'
    },
    valuables: {
        id: 'nfum.platform.nfumCostInfoBox.valuables',
        defaultMessage: 'Valuables'
    },
    fineArtAndCollections: {
        id: 'nfum.platform.nfumCostInfoBox.fineArtAndCollections',
        defaultMessage: 'Fine Art & Collections'
    },
    homeEmergency: {
        id: 'nfum.platform.nfumCostInfoBox.homeEmergency',
        defaultMessage: 'Home Emergency'
    },
    homeEmergencyIncludedAsStandard: {
        id: 'nfum.platform.nfumCostInfoBox.homeEmergencyIncludedAsStandard',
        defaultMessage: 'Home Emergency (included as standard)'
    },
    otherCovers: {
        id: 'nfum.platform.nfumCostInfoBox.otherCovers',
        defaultMessage: 'Other covers'
    },
    employersLiabilityIncludedAsStandard: {
        id: 'nfum.platform.nfumCostInfoBox.employersLiabilityIncludedAsStandard',
        defaultMessage: 'Employers Liability (included as standard)'
    },
    publicLiabilityIncludedAsStandard: {
        id: 'nfum.platform.nfumCostInfoBox.publicLiabilityIncludedAsStandard',
        defaultMessage: 'Public Liability (included as standard)'
    },
    additionalLiability: {
        id: 'nfum.platform.nfumCostInfoBox.additionalLiability',
        defaultMessage: 'Additional liability'
    },
    extendedPublicLiability: {
        id: 'nfum.platform.nfumCostInfoBox.extendedPublicLiability',
        defaultMessage: 'Extended Public Liability'
    },
    personalLegalExpensesIncludedAsStandard: {
        id: 'nfum.platform.nfumCostInfoBox.personalLegalExpensesIncludedAsStandard',
        defaultMessage: 'Personal Legal Expenses (included as standard)'
    },
    personalLegalExpenses: {
        id: 'nfum.platform.nfumCostInfoBox.personalLegalExpenses',
        defaultMessage: 'Personal Legal Expenses'
    },
    personalLegalExpensesExtra: {
        id: 'nfum.platform.nfumCostInfoBox.personalLegalExpensesExtra',
        defaultMessage: 'Personal Legal Expenses Extra'
    },
    personalCyber: {
        id: 'nfum.platform.nfumCostInfoBox.personalCyber',
        defaultMessage: 'Personal Cyber'
    },
    personalCyberIncludedAsStandard: {
        id: 'nfum.platform.nfumCostInfoBox.personalCyberIncludedAsStandard',
        defaultMessage: 'Personal Cyber (included as standard)'
    },
    summary: {
        id: 'nfum.platform.nfumCostInfoBox.summary',
        defaultMessage: 'Summary'
    },
    mutualBonus: {
        id: 'nfum.platform.nfumCostInfoBox.mutualBonus',
        defaultMessage: 'Mutual Bonus'
    },
    totalCost: {
        id: 'nfum.platform.nfumCostInfoBox.totalCost',
        defaultMessage: 'Total Cost'
    },
    referToPolicyDocuments: {
        id: 'nfum.platform.nfumCostInfoBox.referToPolicyDocuments',
        defaultMessage: 'Refer to policy documents for breakdown of costs'
    },
    caravans: {
        id: 'nfum.platform.nfumCostInfoBox.caravans',
        defaultMessage: 'Caravan'
    },
    dogsAndCats: {
        id: 'nfum.platform.nfumCostInfoBox.dogsAndCats',
        defaultMessage: 'Dog and Cat'
    },
    personalAccident: {
        id: 'nfum.platform.nfumCostInfoBox.personalAccident',
        defaultMessage: 'Personal Accident'
    },
    annualTravel: {
        id: 'nfum.platform.nfumCostInfoBox.annualTravel',
        defaultMessage: 'Annual Travel'
    },
    editCover: {
        id: 'nfum.platform.nfumCostInfoBox.editCover',
        defaultMessage: 'Edit Cover'
    },
    switchToYearlyPremium: {
        id: 'nfum.platform.nfumCostInfoBox.switchToYearly',
        defaultMessage: 'Switch to yearly'
    },
    switchToMonthlyPremium: {
        id: 'nfum.platform.nfumCostInfoBox.switchToMonthly',
        defaultMessage: 'Switch to monthly'
    },
});
