import React, { useContext, useCallback } from 'react';
import _ from 'lodash';
import {
    ViewModelForm,
} from 'gw-portals-viewmodel-react';
import { useModal } from '@jutro/components';
import { wizardProps } from 'gw-portals-wizard-react';
import { TranslatorContext } from '@jutro/locale';
import { NfumConfirmModal } from 'nfum-components-platform-react';
import metadata from './ValuablesAwayCard.metadata.json5';
import styles from './ValuablesAwayCard.module.scss';
import messages from './ValuablesAwayCard.messages';
import HighValueBicycles from '../HighValueBicycles/HighValueBicycles';

function ValuablesAwayCard(props) {
    const {
        wizardData: submissionVM,
        updateWizardData,
        paths,
        hideLimitsReferral,
        isDisabled
    } = props;
    const translator = useContext(TranslatorContext);
    const { highValueItemsPath } = paths;
    const TIER_LEVEL_3_CUSTOMER = 'tierLevel3';
    const { showModal } = useModal();
    const contentsAwayExcess =
        submissionVM.value.tierLevel_NFUM === TIER_LEVEL_3_CUSTOMER ? 150 : 100;

    const mapUnspecifiedValuablesOptionsData = useCallback((item) => {
        return {
            code: item.code,
            name: `£${item.name}`
        };
    }, []);

    const toggleContentsAwayCov = useCallback(
        async (isCoverEnabled, path) => {
            if (!isCoverEnabled) {
                const { type } = await showModal(
                    <NfumConfirmModal variant="variant2" />
                );
                if (type === 'CANCEL') {
                    return;
                }
            }
            _.set(submissionVM, path, isCoverEnabled);
            updateWizardData(submissionVM);
        },
        [
            submissionVM,
            updateWizardData,
            showModal
        ]
    );

    const onUnspecifiedBelongingsReplCostChange = useCallback(
        (newCoverLimitCode) => {
            _.set(submissionVM, 'lobData.value.homeLine.lineCoverages.unspecifiedBelongingsReplCost', newCoverLimitCode);
            updateWizardData(submissionVM);
        },
        [submissionVM, updateWizardData]
    );

    const isUnspecifiedContentsAwaySelected = _.get(submissionVM, 'lobData.value.homeLine.lineCoverages.isUnspecifiedContentsAwaySelected');
    const scheduleItems = _.get(submissionVM, `${highValueItemsPath}.scheduleItems`) || [];
    const isTierLevel3Customer = _.get(submissionVM, 'lobData.value.homeLine.lineCoverages.contentsCovTier') === TIER_LEVEL_3_CUSTOMER;
    const isValuablesAwayFromHome = () => scheduleItems
        .filter(({ itemData }) => itemData.ArticleGeographicLimit.typeCodeValue === 'AwayFromHomeWithinUK').length > 0;
    const isUnspecifiedContentsAwaySwitchDisabled = isTierLevel3Customer;
    const getUnspecifiedContentsAwaySwitchLabel = () => {
        if (isUnspecifiedContentsAwaySwitchDisabled) {
            return translator(messages.coverIncluded);
        }
        return isUnspecifiedContentsAwaySelected
            ? translator(messages.removeCover)
            : translator(messages.addCover);
    };
    const getUnspecifiedName = () => {
        const path = 'lobData.value.homeLine.lineCoverages.unspecifiedBelongingsReplCostAvailableValues';
        const availableValues = _.get(submissionVM, path);
        if (availableValues) {
            const selectedPath = 'lobData.value.homeLine.lineCoverages.unspecifiedBelongingsReplCost';
            const selectedValue = _.get(submissionVM, selectedPath);
            if (selectedValue) {
                return `£${availableValues.find((item) => item.code === selectedValue)?.name}`;
            }
        }
        return 'Not selected';
    };
    const getUnspecifiedCode = () => {
        const selectedPath = 'lobData.value.homeLine.lineCoverages.unspecifiedBelongingsReplCost';
        const path = 'lobData.value.homeLine.lineCoverages.unspecifiedBelongingsReplCostAvailableValues';
        const availableValues = _.get(submissionVM, path);
        if (availableValues) {
            return _.get(submissionVM, selectedPath);
        }
        return 'Not selected';
    };
    const getUnspecifiedAvailableValues = () => {
        const path = 'lobData.value.homeLine.lineCoverages.unspecifiedBelongingsReplCostAvailableValues';
        const availableValues = _.get(submissionVM, path);
        if (availableValues) {
            return availableValues.map(mapUnspecifiedValuablesOptionsData);
        }
        return [];
    };
    const overrideProps = {
        valuablesAwayContentNotice: {
            visible: isValuablesAwayFromHome() && !isTierLevel3Customer
        },
        unspecifiedValuables: {
            disabled: isDisabled,
            visible: isUnspecifiedContentsAwaySelected,
            value: {
                code: getUnspecifiedCode(),
                name: getUnspecifiedName()
            },
            availableValues: getUnspecifiedAvailableValues(),
            onValueChange: onUnspecifiedBelongingsReplCostChange
        },
        enableValuablesAwaySwitch: {
            disabled: isUnspecifiedContentsAwaySwitchDisabled || isDisabled,
            onValueChange: toggleContentsAwayCov,
            label: getUnspecifiedContentsAwaySwitchLabel()
        },
        valuablesAwayDescription: {
            value: metadata.valuablesAwayDescription
        },
        highValueBicycles: {
            visible: isUnspecifiedContentsAwaySelected,
            wizardData: submissionVM,
            updateWizardData: updateWizardData,
            paths: paths,
            hideLimitsReferral: hideLimitsReferral,
            isDisabled: isDisabled
        },
        contentsAwayAdditionalExcess: {
            content: `£${contentsAwayExcess}`
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveComponentMap: {
            highValueBicycles: HighValueBicycles
        }
    };

    return (
        <ViewModelForm
            model={submissionVM}
            uiProps={metadata.componentContent}
            overrideProps={overrideProps}
            classNameMap={resolvers.resolveClassNameMap}
            componentMap={resolvers.resolveComponentMap}
        />
    );
}

ValuablesAwayCard.propTypes = wizardProps;
export default ValuablesAwayCard;
