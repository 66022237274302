import { defineMessages } from 'react-intl';

export default defineMessages({
    homeAndLifestyleInsurance: {
        id: 'renewal.ngh.views.renewal-summary.policyDetails.homeAndLifestyleInsurance',
        defaultMessage: 'NFU Mutual Home Insurance'
    },
    buildings: {
        id: 'renewal.ngh.views.renewal-summary.policyDetails.buildings',
        defaultMessage: 'Buildings'
    },
    contents: {
        id: 'renewal.ngh.views.renewal-summary.policyDetails.contents',
        defaultMessage: 'Contents'
    },
    buildingsAndContents: {
        id: 'renewal.ngh.views.renewal-summary.policyDetails.buildingsAndContents',
        defaultMessage: 'Buildings & contents'
    },
    catdog: {
        id: 'renewal.ngh.views.renewal-summary.policyDetails.catdog',
        defaultMessage: 'Cat/Dog'
    },
    personalAccident: {
        id: 'renewal.ngh.views.renewal-summary.policyDetails.personalAccident',
        defaultMessage: 'Personal Accident'
    },
    homeEmergencyCover: {
        id: 'renewal.ngh.views.renewal-summary.policyDetails.homeEmergencyCover',
        defaultMessage: 'Home Emergency'
    },
    personalLegalExpenses: {
        id: 'renewal.ngh.views.renewal-summary.policyDetails.personalLegalExpenses',
        defaultMessage: 'Personal Legal Expenses'
    },
    personalLegalExpensesExtra: {
        id: 'renewal.ngh.views.renewal-summary.policyDetails.personalLegalExpensesExtra',
        defaultMessage: 'Personal Legal Expenses Extra'
    },
    contentsAway: {
        id: 'renewal.ngh.views.renewal-summary.policyDetails.contentsAwayFromHome',
        defaultMessage: 'Contents away'
    },
    bicycleExtensionCoverTitle: {
        id: 'renewal.ngh.views.renewal-summary.policyDetails.bicycleExtensionCover',
        defaultMessage: 'Cycling Protection'
    },
    personalCyberCover: {
        id: 'renewal.ngh.views.renewal-summary.policyDetails.personalCyberCover',
        defaultMessage: 'Personal Cyber Cover'
    },
    caravan: {
        id: 'renewal.ngh.views.renewal-summary.policyDetails.caravan',
        defaultMessage: 'Caravan'
    },
    travel: {
        id: 'renewal.ngh.views.renewal-summary.policyDetails.travel',
        defaultMessage: 'Travel'
    },
    policyType: {
        id: 'renewal.ngh.views.renewal-summary.policyDetails.policyType',
        defaultMessage: 'Policy type'
    },
    coverType: {
        id: 'renewal.ngh.views.renewal-summary.policyDetails.coverType',
        defaultMessage: 'Cover type'
    },
    policyHolder: {
        id: 'renewal.ngh.views.renewal-summary.policyDetails.policyHolder',
        defaultMessage: 'Policyholder'
    },
    emailAddress: {
        id: 'renewal.ngh.views.renewal-summary.policyDetails.emailAddress',
        defaultMessage: 'Email address'
    },
    address: {
        id: 'renewal.ngh.views.renewal-summary.policyDetails.address',
        defaultMessage: 'Address'
    },
    policyStartAndEndDate: {
        id: 'renewal.ngh.views.renewal-summary.policyDetails.policyStartAndEndDate',
        defaultMessage: 'Policy start and end date'
    },
    paymentType: {
        id: 'renewal.ngh.views.renewal-summary.policyDetails.paymentType',
        defaultMessage: 'Payment type'
    },
    creditOrDebitCard: {
        id: 'renewal.ngh.views.renewal-summary.policyDetails.creditOrDebitCard',
        defaultMessage: 'Credit/Debit card'
    },
    directDebit: {
        id: 'renewal.ngh.views.renewal-summary.policyDetails.directDebit',
        defaultMessage: 'Direct Debit'
    },
    yes: {
        id: 'quoteandbind.ngh.views.policy-summary.policyDetails.yes',
        defaultMessage: 'Yes'
    },
    no: {
        id: 'quoteandbind.ngh.views.policy-summary.policyDetails.no',
        defaultMessage: 'No'
    },
    optedOutOfAutoRenewal: {
        id: 'quoteandbind.ngh.views.policy-summary.policyDetails.optedOutOfAutoRenewal',
        defaultMessage: 'Opted out of auto renewal'
    },
    customerCareDocumentFormat: {
        id: 'quoteandbind.ngh.views.policy-summary.policyDetails.customerCareDocumentFormat',
        defaultMessage: 'Alternative documents requested'
    },
    noPreference: {
        id: 'quoteandbind.ngh.views.policy-summary.policyDetails.noPreference',
        defaultMessage: 'No preference'
    },
    bespokeInsurance:{
        id: 'quoteandbind.ngh.views.policy-summary.policyDetails.bespokeInsurance',
        defaultMessage: 'NFU Mutual Bespoke Home Insurance'
    },
    valuables: {
        id: 'quoteandbind.ngh.views.policy-summary.policyDetails.valuables',
        defaultMessage: 'Valuables' 
    },
    fineartAndCollection: {
        id: 'quoteandbind.ngh.views.policy-summary.policyDetails.fineartAndCollection',
        defaultMessage: 'Fine art & collections' 
    }
});
