import { defineMessages } from 'react-intl';

export default defineMessages({
    returnToWebsite: {
        id: 'quoteandbind.ngh.views.success.Return to website',
        defaultMessage: 'Return to website'
    },
    thankYouWithPaperDocuments: {
        id: 'quoteandbind.ngh.views.success.thankYouWithPaperDocuments',
        defaultMessage: 'Thank you for purchasing your home insurance policy from NFU Mutual. A paper copy of your policy documents will be sent you in the post shortly. Your policy documents are available in your online account which you can create below.'
    },
    thankYouWithoutPaperDocuments: {
        id: 'quoteandbind.ngh.views.success.thankYouWithoutPaperDocuments',
        defaultMessage: 'Thank you for purchasing your Home Insurance policy from NFU Mutual. Your policy documents will be available in your online account which you can create below.'
    },
    thankYouWithPaperDocumentsForLifestyle: {
        id: 'quoteandbind.ngh.views.success.thankYouWithPaperDocumentsForLifestyle',
        defaultMessage: 'Thank you for choosing NFU Mutual for your Insurance policy. A paper copy of your policy documents will be sent you in the post shortly.'
    },
    thankYouWithoutPaperDocumentsForLifestyle: {
        id: 'quoteandbind.ngh.views.success.thankYouWithoutPaperDocumentsForLifestyle',
        defaultMessage: 'Thank you for choosing NFU Mutual for your Insurance policy.'
    },
    transactionTitle: {
        id: 'quoteandbind.ngh.views.success.transactionTitle',
        defaultMessage: 'Your payment was successful.'
    },
    transactionDDTitle: {
        id: 'quoteandbind.ngh.views.success.transactionDDTitle',
        defaultMessage: 'Your Direct Debit details have been accepted'
    },
    thankYouChoosingNfum: {
        id: 'quoteandbind.ngh.views.success.thankYouChoosingNfum',
        defaultMessage: 'Thank you for choosing NFU Mutual.'
    },
    transactionSupplimentaryTitle: {
        id: 'quoteandbind.ngh.views.success.transactionSupplimentaryTitle',
        defaultMessage: 'Your card payment has been taken and your Direct Debit details have been accepted'
    },
    YourOnlineAccount: {
        id: 'quoteandbind.ngh.views.success.YourOnlineAccount',
        defaultMessage: 'Why register for an online account?'
    },
    finalDescriptionInfo: {
        id: 'quoteandbind.ngh.views.success-page.finalDescriptionInfo',
        defaultMessage: 'NFU Mutual Home Insurance underwritten by NFU Mutual'
    },
    accountStatusRegistered: {
        id: 'quoteandbind.ngh.views.success.accountStatusRegistered',
        defaultMessage: 'Your online account'
    },
    accountStatusInvitedOrIncomplete: {
        id: 'quoteandbind.ngh.views.success.accountStatusInvitedOrIncomplete',
        defaultMessage: 'Register for your online account'
    },
    accountStatusEligibleReady: {
        id: 'quoteandbind.ngh.views.success.accountStatusEligibleReady',
        defaultMessage: 'Register for your online account'
    },
    accountStatusEligible: {
        id: 'quoteandbind.ngh.views.success.accountStatusEligible',
        defaultMessage: 'Want to manage your policy online?'
    },
    contactUsHeader: {
        id: 'quoteandbind.ngh.views.success.contactUsHeader',
        defaultMessage: 'Need to get in touch?'
    },

});
