import React from 'react';
import _ from 'lodash';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import metadata from './OnlineAccountStatusRegisterEligible.metadata.json5';
import styles from './OnlineAccountStatusRegisterEligible.module.scss';
import AccessListItems from '../AccessListItems/AccessListItems';
import CallOrContactInfo from '../../../components/CallOrContactInfo/CallOrContactInfo';

function OnlineAccountStatusRegisterEligible(props) {
    const { submissionVM } = props;
    const producerDesc = _.get(submissionVM.value, 'baseData.producerDetails_NFUM.producerCodeDescription', '');
    const producerTele = _.get(submissionVM.value, 'baseData.producerDetails_NFUM.producerCodeTelephone', '');
    const isBespoke = _.get(submissionVM.value, 'baseData.producerDetails_NFUM.isBespoke', '');
    const showAgencyDetails = _.get(submissionVM.value, 'baseData.producerDetails_NFUM.showProducerDetails', '');
    const isMutualDirect = _.get(submissionVM.value, 'baseData.producerDetails_NFUM.isMutualDirect', true);
    const overrideProps = {
        contact: {
            producerDesc: producerDesc,
            producerTele: producerTele,
            isBespoke: isBespoke,
            showAgencyDetails: showAgencyDetails,
            isMutualDirect
        },
        accessListItems: {
            status: 'eligible'
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveComponentMap: {
            accessListItems: AccessListItems,
            callOrContactInfo: CallOrContactInfo,
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            classNameMap={resolvers.resolveClassNameMap}
            overrideProps={overrideProps}
            componentMap={resolvers.resolveComponentMap}
        />
    );
}

export default OnlineAccountStatusRegisterEligible;
