import React, {
    useState, useContext, useEffect, useCallback
} from 'react';
import _ from 'lodash';
import {
    ViewModelServiceContext,
    ViewModelForm,
} from 'gw-portals-viewmodel-react';
import { AddressLookupService } from 'gw-capability-address';
import { ErrorHandler } from 'nfum-portals-utils-react';
import { useTranslator } from '@jutro/locale';
import { useAuthentication } from 'gw-digital-auth-react';
import { useHistory } from 'react-router-dom';
import AddressInfo from '../AddressInfo/AddressInfo';
import EditAddressForm from './EditAddressForm/EditAddressForm';
import metadata from './AddressSearch.metadata.json5';
import messages from './AddressSearch.messages';
import styles from './AddressSearch.module.scss';

function AddressSearch(props) {
    const {
        value: addressVM,
        onAddressChanged,
        setIsAppLoading
    } = props;
    const translator = useTranslator();
    const history = useHistory();
    const { authHeader } = useAuthentication();
    const viewModelService = useContext(ViewModelServiceContext);
    const [formData, setFormData] = useState({ houseNameText: '', postalCodeText: '', selectedAddressIndex: undefined });
    const [errorText, setErrorText] = useState(undefined);
    const [isSearchButtonDisabled, setIsSearchButtonDisabled] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isEditAddressMode, setIsEditAddressMode] = useState(false);
    const [selectedAddress, setSelectedAddress] = useState(addressVM);
    const [isAddressVisible, setIsAddressVisible] = useState(false);
    const [addressMatches, setAddressMatches] = useState([]);
    const [isAddressesDropdownListVisible, setIsAddressesDropdownListVisible] = useState(false);
    const POSTALCODE_REGEX = '^[a-zA-Z0-9 ]{0,8}$|^$';

    useEffect(() => {
        if (addressVM?.aspects?.valid && addressVM?.aspects?.subtreeValid) {
            setIsEditAddressMode(false);
            setIsAddressVisible(true);
        }
    }, [addressVM]);

    const writeValue = (value, path) => {
        const clonedFormData = _.cloneDeep(formData);
        _.set(clonedFormData, path, value);
        setFormData(clonedFormData);
        setErrorText(undefined);
    };
    const isValidPostalFormat = useCallback((value) => {
        const regex = new RegExp(POSTALCODE_REGEX);
        return regex.test(value);
    }, []);
    const writePostalCode = (value, path) => {
        if (!isValidPostalFormat(value)) return;
        setAddressMatches([]);
        const clonedFormData = _.cloneDeep(formData);
        _.set(clonedFormData, path, value);
        setFormData(clonedFormData);
        setErrorText(undefined);
    };

    const handleError = (err, scrollPos) => {
        setIsAppLoading(false);
        window.scrollTo(0, scrollPos);
        setIsLoading(false);
        setErrorText(ErrorHandler.getDisplayableErrorMessage(err));
        setIsSearchButtonDisabled(false);
    };
    const handlePostalCodeError = (err, scrollPos) => {
        setIsAppLoading(false);
        window.scrollTo(0, scrollPos);
        setIsLoading(false);
        setErrorText(translator(messages.addressNotFound));
        setIsSearchButtonDisabled(false);
    };

    const editAddress = () => {
        setIsEditAddressMode(true);
        setIsAddressVisible(false);
    };

    const showSearchAddress = () => {
        setIsEditAddressMode(false);
        setIsAddressVisible(false);
        window.scrollTo(0, 450);
    };

    const submitAddress = (newAddress) => {
        const clonedAddress = viewModelService.clone(selectedAddress);
        clonedAddress.value = {
            ...newAddress.value
        };
        setSelectedAddress(clonedAddress);
        setIsEditAddressMode(false);
        setIsAddressVisible(true);
        onAddressChanged(clonedAddress);
    };

    const onAddressSelection = (index, options) => {
        const address = options[index];
        setIsLoading(true);
        setIsAppLoading(true);
        const scrollPos = window.scrollY;
        AddressLookupService.lookupAddressUsingString(address.moniker).then((result) => {
            setIsAppLoading(false);
            window.scrollTo(0, scrollPos);
            setIsLoading(false);
            if (!result?.matches[0]?.address?.addressLine1) {
                history.push('/contact-us');
            }
            const clonedAddress = viewModelService.clone(selectedAddress);
            clonedAddress.value = {
                ...result.matches[0].address
            };
            setSelectedAddress(clonedAddress);
            setFormData({ ...formData, selectedAddressIndex: index });
            setIsEditAddressMode(false);
            setIsAddressVisible(true);
            onAddressChanged(clonedAddress);
        }).catch((err) => handleError(err, scrollPos));
    };

    const onSearchAddress = () => {
        setIsAppLoading(true);
        const scrollPos = window.scrollY;
        setIsSearchButtonDisabled(true);
        setIsLoading(true);
        setFormData({ ...formData, selectedAddressIndex: undefined });
        AddressLookupService.lookupAddressUsingStringAndFilterByPostalCode(
            formData.houseNameText,
            formData.postalCodeText,
            authHeader
        ).then((addresses) => {
            setIsAppLoading(false);
            window.scrollTo(0, scrollPos);
            setIsLoading(false);
            setIsSearchButtonDisabled(false);
            setAddressMatches(addresses.matches);
            if (addresses.matches.length > 1) {
                setIsAddressesDropdownListVisible(true);
            } else if (addresses.matches.length === 1) {
                onAddressSelection(0, addresses.matches);
            }
        }).catch((err) => handlePostalCodeError(err, scrollPos));
    };

    const getAddressDisplayName = (location) => {
        if (!location) return '';
        const titleParts = [
            location.addressLine1,
            location.addressLine2,
            location.addressLine3,
            location.addressLine4_NFUM,
            location.city,
            location.county,
            location.postalCode
        ];
        return titleParts.filter((part) => part).join(', ');
    };

    const overrideProps = {
        houseNameSearch: {
            onValueChange: writeValue,
            disabled: isLoading,
            error: errorText
        },
        postalCodeSearch: {
            onValueChange: writePostalCode,
            isFullSize: true,
            disabled: isLoading,
            error: errorText
        },
        searchAddressContainer: {
            visible: !isEditAddressMode && !isAddressVisible
        },
        selectedAddressContainer: {
            visible: isAddressVisible,
        },
        selectedAddressInfo: {
            value: selectedAddress
        },
        errorMessage: {
            content: errorText,
        },
        addressesListDropdown: {
            visible: isAddressesDropdownListVisible,
            availableValues: addressMatches.map((singleAddress, index) => {
                return {
                    code: index,
                    name: getAddressDisplayName(singleAddress.address)
                };
            }),
            value: formData.selectedAddressIndex,
            onValueChange: (index) => onAddressSelection(index, addressMatches)
        },
        searchButton: {
            disabled: isSearchButtonDisabled || !formData?.postalCodeText || errorText,
            onClick: onSearchAddress
        },
        editAddressContainer: {
            visible: isEditAddressMode
        },
        editAddressForm: {
            addressVM: selectedAddress,
            onAddressSubmitted: submitAddress,
            onSearchAgain: showSearchAddress
        },
        enterAddressManuallyButton: {
            onClick: editAddress
        },
        editAddressButton: {
            onClick: editAddress
        },
        returnToSearchAddressButton: {
            onClick: showSearchAddress
        }
    };

    const resolvers = {
        resolveComponentMap: {
            addressinfo: AddressInfo,
            editaddressform: EditAddressForm
        },
        resolveClassNameMap: styles
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={formData}
            onValueChange={writeValue}
            overrideProps={overrideProps}
            classNameMap={resolvers.resolveClassNameMap}
            componentMap={resolvers.resolveComponentMap}
        />
    );
}

export default AddressSearch;
