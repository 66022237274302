import { defineMessages } from 'react-intl';

export default defineMessages({
    valuablesAwayTitle: {
        id: 'quoteandbind.ngh.views.valuablesAwayCard.valuablesAwayTitle',
        defaultMessage: 'Contents away from the home (including bicycles)'
    },
    notice: {
        id: 'quoteandbind.ngh.views.valuablesAwayCard.notice',
        defaultMessage: 'As you have insured specified valuable items away from the home, you may wish to add cover for unspecified contents away from the home'
    },
    valuablesAwayDescription: {
        id: 'quoteandbind.ngh.views.valuablesAwayCard.valuablesAwayDescription',
        defaultMessage: 'This cover is for all other unspecified items you would like to insure when they are away from your home. You will be able to specify the amount of cover that you need.'
    },
    anExcessOf: {
        id: 'quoteandbind.ngh.views.valuablesAwayCard.anExcessOf',
        defaultMessage: 'An excess of '
    },
    applies: {
        id: 'quoteandbind.ngh.views.valuablesAwayCard.applies',
        defaultMessage: ' applies. Any voluntary excess will not apply to this cover.'
    },
    removeCover: {
        id: 'quoteandbind.ngh.views.valuablesAwayCard.removeCover',
        defaultMessage: 'Remove cover'
    },
    addCover: {
        id: 'quoteandbind.ngh.views.valuablesAwayCard.addCover',
        defaultMessage: 'Add cover'
    },
    coverIncluded: {
        id: 'quoteandbind.ngh.views.valuablesAwayCard.includedAsStandard',
        defaultMessage: 'Included as standard'
    },
    unspecifiedValuables: {
        id: 'quoteandbind.ngh.views.valuablesAwayCard.unspecifiedValuables',
        defaultMessage: 'Unspecified contents away from the home'
    },
    toolTip: {
        id: 'quoteandbind.ngh.views.valuablesAwayCard.valuableType.tooltip',
        defaultMessage: 'This is an informational tooltip'
    },
    delete: {
        id: 'quoteandbind.ngh.views.valuablesAwayCard.delete',
        defaultMessage: 'Delete'
    },
    highValueBicycleReplacementColumn: {
        id: 'quoteandbind.ngh.views.valuablesAwayCard.highValueBicycleReplacementColumn',
        defaultMessage: 'Replacement cost'
    },
});
