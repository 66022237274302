import React, {
    useState, useContext, useEffect, useCallback
} from 'react';
import _ from 'lodash';
import {
    ViewModelServiceContext,
    ViewModelForm,
} from 'gw-portals-viewmodel-react';
import {
    useHistory
} from 'react-router-dom';
import { AddressLookupService } from 'gw-capability-address';
import { useAuthentication } from 'gw-digital-auth-react';
import { useCommonTagManager } from 'nfum-portals-utils-react';
import { TranslatorContext } from '@jutro/locale';
import metadata from './EditAddressForm.metadata.json5';
import styles from './EditAddressForm.module.scss';
import messages from './EditAddressForm.messages';


function EditAddressForm(props) {
    const {
        addressVM,
        onAddressSubmitted,
        onSearchAgain
    } = props;
    const { authHeader } = useAuthentication();
    const viewModelService = useContext(ViewModelServiceContext);
    const [selectedAddress, setSelectedAddress] = useState(addressVM);
    const [isLoading, setIsLoading] = useState(false);
    const history = useHistory();
    const TEXT_FIELD_MAX_LENGTH = 60;
    const POSTCODE_FIELD_MAX_LENGTH = 8;
    const POSTALCODE_REGEX = '^[a-zA-Z0-9 ]{0,8}$|^$';

    const {
        pushLinkClickInfo
    } = useCommonTagManager();
    const translator = useContext(TranslatorContext);


    useEffect(() => {
        const addressVmWithContext = viewModelService
            .changeContext(addressVM, { NewAddress: true });
        setSelectedAddress(addressVmWithContext);
    }, [addressVM, viewModelService]);

    const handleChange = (value, changedPath) => {
        const clonedAddress = viewModelService.clone(selectedAddress);
        _.set(clonedAddress.value, changedPath, value);
        setSelectedAddress(clonedAddress);
    };

    const submitAddress = () => {
        setIsLoading(true);
        onAddressSubmitted(selectedAddress);
        AddressLookupService.lookupAddressUsingStringAndFilterByPostalCode(
            selectedAddress.value.addressLine1,
            selectedAddress.value.postalCode,
            authHeader
        ).then((addresses) => {
            setIsLoading(false);
            if (addresses.matches.length > 1) {
                history.push('/contact-us');
            } else if (addresses.matches.length === 1) {
                onAddressSubmitted(selectedAddress);
            }
            setIsLoading(false);
        }).catch(() => {
            setIsLoading(false);
            history.push('/contact-us');
        });
    };

    const formatPostalCode = (postalCode, path) => {
        const upperCasePostalCode = _.toUpper(postalCode);
        const trimmedPostalCode = upperCasePostalCode?.trim();
        let finalPostalCode = trimmedPostalCode;
        if (trimmedPostalCode
            && trimmedPostalCode.length === 5
            && !trimmedPostalCode.includes(' ')) {
            finalPostalCode = `${trimmedPostalCode.substring(0, 2)} ${trimmedPostalCode.substring(2)}`;
        } else if (trimmedPostalCode
            && trimmedPostalCode.length === 6
            && !trimmedPostalCode.includes(' ')) {
            finalPostalCode = `${trimmedPostalCode.substring(0, 3)} ${trimmedPostalCode.substring(3)}`;
        } else if (trimmedPostalCode
            && (trimmedPostalCode.length === 7 || trimmedPostalCode.length === 8)
            && !trimmedPostalCode.includes(' ')) {
            finalPostalCode = `${trimmedPostalCode.substring(0, 4)} ${trimmedPostalCode.substring(4)}`;
        }
        handleChange(finalPostalCode, path);
    };

    const isValidPostalFormat = useCallback((value) => {
        const regex = new RegExp(POSTALCODE_REGEX);
        return regex.test(value);
    }, []);

    const isValidPostalCode = useCallback((value, path) => {
        if (!isValidPostalFormat(value)) return;
        handleChange(value, path);
    }, [handleChange, isValidPostalFormat]);

    const onContactUsClicked = () => {
        const url = 'https://www.nfumutual.co.uk/contact-us/home-insurance';
        pushLinkClickInfo(translator(messages.contactUs), url);
        window.open(url, '_blank');
    };

    const textInputRole = document.querySelectorAll('.jut__InputField__input');
    textInputRole.forEach((role) => {
        if (role) {
            role.setAttribute('role', 'textbox');
            role.setAttribute('aria-label', role.ariaLabel);
        }
    });

    const overrideProps = {
        addressLine1: {
            maxLength: TEXT_FIELD_MAX_LENGTH
        },
        addressLine2: {
            maxLength: TEXT_FIELD_MAX_LENGTH,
            showOptional: true
        },
        addressLine3: {
            maxLength: TEXT_FIELD_MAX_LENGTH,
            showOptional: true
        },
        townCity: {
            maxLength: TEXT_FIELD_MAX_LENGTH,
        },
        county: {
            maxLength: TEXT_FIELD_MAX_LENGTH,
            showOptional: true
        },
        postcode: {
            maxLength: POSTCODE_FIELD_MAX_LENGTH,
            maxLengthValidationMessage: '',
            onBlur: formatPostalCode,
            onValueChange: isValidPostalCode,
        },
        submitButton: {
            disabled: !selectedAddress?.aspects?.valid
                || !selectedAddress?.aspects?.subtreeValid
                || isLoading,
            onClick: submitAddress,
        },
        searchAgainButton: {
            onClick: onSearchAgain
        },
        contactUs: {
            onClick: onContactUsClicked
        },
    };

    const resolvers = {
        resolveClassNameMap: styles
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={selectedAddress}
            onValueChange={handleChange}
            overrideProps={overrideProps}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
}

export default EditAddressForm;
